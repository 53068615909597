import logo from "../assets/RetireHow 1.png";

const Header = () => {
  return (
    <div className='self-center lg:self-start'>
      <img
        src={logo}
        alt='logo'
        className='h-[6rem] w-auto pt-[1.5rem] lg:pt-[3.5rem] lg:h-[8.68rem] xl:h-[9rem] lg:pl-[4rem] lg:w-auto lg:self-start self-center'
      />
    </div>
  );
};

export default Header;
