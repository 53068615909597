import "./style.css";
import Header from "./Header";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { useForm } from "react-hook-form";
import hills from "../assets/hills.svg";
import chillingChair from "../assets/chillingChair.svg";
import couple from "../assets/couple (2).svg";
import tree from "../assets/tree.svg";
import bush from "../assets/bush.svg";
import leftBush from "../assets/leftBush.svg";
import sunFrame from "../assets/sunFrame.svg";
import mobileSun from "../assets/mobileSun.svg";
import grassRoot from "../assets/grassRoot.svg";
import birdsGroup from "../assets/birdsGroup.svg";
import marketTable from "../assets/marketTable.svg";
import WaitlistModal from "../helpers/WaitListModal";
import VideoModal from "../helpers/VideoModal";

const Home = () => {
  const [isContactShown, setContactsShown] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const popupRef = useRef(null);

  const [videoModalShown, setVideoModalShown] = useState(false);
  const videoPopupRef = useRef(null);

  function isValidEmail() {
    return emailRegex.test(email);
  }

  const saveEmail = async () => {
    try {
      const url =
        "https://skysheetapi.onrender.com/api/V1/adddata/1LBdCNlhb0UrEBCWx8RAWHTnb2BuQk0nUD2LT6AXX_L4?sheetIndex=0";

      const options = {
        method: "POST",
        body: JSON.stringify({ Email: email }),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();

      if (response.ok) {
        popupRef.current.open();
        setValidEmail(false);
        setEmail("");
      } else {
        alert(responseObj.message);
      }

      console.log(response, "aioush");
      console.log(responseObj, "responseoBj");
    } catch (e) {
      console.log(e);
    }
  };

  const addToWaitList = async () => {
    console.log(isValidEmail(), "email validation");

    if (isValidEmail() === true) {
      saveEmail();
    } else {
      console.log("fun called");

      if (email === "") {
        alert("Enter The Email Id");
      } else {
        alert("Invalid Email");
      }
    }
  };

  useEffect(() => {
    setVideoModalShown(true);
  }, []);

  console.log(validEmail, "validatioon");

  return (
    <div className='main font-poppins'>
      {/* <div className='frame-container'></div> */}
      <div className='px-[1rem] lg:px-[2rem] flex flex-col gap-[1.5rem] w-full'>
        <Header />

        <div className='flex flex-col items-center gap-[1rem] lg:absolute lg:top-[6.5rem] w-full '>
          <div className='flex flex-col items-center gap-[0.5rem]  lg:max-w-[80%] 3xl:max-w-[60%] '>
            <h1 className='text-[1.5rem] lg:text-[2.5rem] xl:text-[4rem] text-center font-extrabold font-manrope  '>
              LAUNCHING SOON
            </h1>
            <p
              style={{ fontFamily: "Arial" }}
              className='font-raleway text-center text-[0.875rem] font-normal text-[#000] tracking-[0.14px]   lg:text-[1.5rem]'>
              Discover the freedom to retire in style and travel on your terms
              with Retirehow.com and TravelGlobal.ca. Join the expanding list of
              individuals, business owners and families eager to dive into the
              realm of retirement planning and long term stays abroad.
            </p>
          </div>

          <div
            style={{ fontFamily: "Arial" }}
            className={`bg-white rounded-[100px]  py-[0.4rem] sm:py-[0.75rem] xl:py-[0.5rem] pl-[1.5rem] pr-[1rem] z-40 flex w-full max-w-[573px]`}>
            <input
              placeholder='Enter your mail'
              type='email'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className='outline-none text-[#565656] placeholder:text-[#565656] text-[0.50rem] xsm:text-[0.65rem] sm:text-[1rem] placeholder:font-normal font-bold flex-1 sm:pr-[1rem] sm:flex-1 '
            />

            <button
              type='button'
              onClick={addToWaitList}
              className='bg-[#FF7E10]     py-[0.75rem] px-[1rem] rounded-[6.25rem] shrink-0 text-[0.45rem] sm:text-[0.875rem]   font-bold text-white'>
              Join the Waitlist
            </button>
            <Popup
              ref={popupRef}
              modal
              position={"center center"}
              open={validEmail}>
              {(close) => <WaitlistModal close={close} />}
            </Popup>
          </div>
          <div className='bg-white text-[#FF7E10] py-[0.37rem] px-[0.74rem] rounded-[2.3rem] flex flex-row items-center z-30'>
            <div className='flex flex-row items-center gap-[0.4rem]'>
              <Icon
                icon='ic:baseline-phone'
                className='text-[0.875rem] sm:text-[1rem] lg:text-[1.5rem]'
              />
              <p className='font-manrope text-[0.65rem] xsm:text-[0.875rem] md:text-[1rem] lg:text-[1.5rem] font-bold'>
                +1(289) 815-3631
              </p>
            </div>

            <hr className='w-[0.74px] bg-[#CDCDCD] h-[1.5rem] xsm:h-[27px]  mx-[0.375rem] ' />

            <div className='flex flex-row items-center gap-[0.4rem]'>
              <Icon
                icon='ic:baseline-email'
                className='text-[0.875rem] xsm:text-[1rem] lg:text-[1.5rem]'
              />
              <p className='font-manrope text-[0.65rem] xsm:text-[0.875rem] md:text-[1rem] lg:text-[1.5rem] font-bold'>
                Rao.movva@retirehow.com
              </p>
            </div>
          </div>

          <div
            className={`flex  items-center h-auto z-50 absolute ${
              isContactShown ? "left-[30px]" : "left-[-52px]"
            }  top-[55%] xsm:top-[45%] transition-all duration-4000 lg:hidden`}>
            <div className='p-[0.75rem] xl:p-[0.5rem] bg-white flex flex-col gap-[1rem] rounded-[3.125rem] '>
              <a
                href={
                  "https://www.facebook.com/share/Wa4ZNZ8DjnuMudjC/?mibextid=qi2Omg"
                }
                target='_blank'
                style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
                className='p-[0.375rem] bg-[#FF7E10] hover:shadow-xl rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
                <Icon
                  icon='ri:facebook-fill'
                  className='text-white text-[1rem]'
                />
              </a>
              <a
                href={
                  "https://www.linkedin.com/in/rao-movva-pfp%C2%AE-cim%C2%AE-ciwm-fcsi%C2%AE-1826263/"
                }
                target='_blank'
                style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
                className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
                <Icon
                  icon='ri:linkedin-fill'
                  className='text-white text-[1rem]'
                />
              </a>
              <a
                href={
                  "https://www.instagram.com/founder_ceo_travelglobal.ca?igsh=bm10cXBka2g5Y3dh"
                }
                target='_blank'
                style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
                className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
                <Icon
                  icon='ri:instagram-fill'
                  className='text-white text-[1rem]'
                />
              </a>
              <a
                href={
                  "https://x.com/FallowRaoMovva?t=TtOXJBp-ztKfOUZNiDYFXQ&s=09"
                }
                target='_blank'
                style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
                className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
                <Icon
                  icon='ri:twitter-fill'
                  className='text-white text-[1rem]'
                />
              </a>
              <a
                href={"https://www.youtube.com/@movvarao1060"}
                target='_blank'
                style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
                className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
                <Icon
                  icon='ri:youtube-fill'
                  className='text-white text-[1rem]'
                />
              </a>
            </div>
            <button
              type='button'
              onClick={() => setContactsShown(!isContactShown)}
              className='w-[2.5rem] h-[2.5rem] bg-white flex justify-center items-center  rounded-r-[3.125rem]  xl:hidden'>
              <Icon
                icon={
                  !isContactShown
                    ? "fluent:chevron-right-12-filled"
                    : "fluent:chevron-left-12-filled"
                }
                className='text-[1rem] sm:text-[1.5rem] text-[#000000]'
              />
            </button>
          </div>
        </div>

        <div className='p-[1rem] bg-white xl:flex flex-col items-center gap-[1rem] rounded-[3.125rem] left-[30px] absolute top-[35%] hidden'>
          <a
            href={
              "https://www.facebook.com/share/Wa4ZNZ8DjnuMudjC/?mibextid=qi2Omg"
            }
            target='_blank'
            //style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
            className='p-[0.5rem] flex justify-center items-center bg-[#FF7E10] rounded-full cursor-pointer transition duration-300 hover:scale-110'>
            <Icon icon='ri:facebook-fill' className='text-white text-[1rem]' />
          </a>
          <a
            target='_blank'
            href={
              "https://www.linkedin.com/in/rao-movva-pfp%C2%AE-cim%C2%AE-ciwm-fcsi%C2%AE-1826263/"
            }
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
            className='p-[0.5rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
            <Icon icon='ri:linkedin-fill' className='text-white text-[1rem]' />
          </a>

          <a
            target='_blank'
            href={
              "https://www.instagram.com/founder_ceo_travelglobal.ca?igsh=bm10cXBka2g5Y3dh"
            }
            //style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
            className='p-[0.5rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
            <Icon icon='ri:instagram-fill' className='text-white text-[1rem]' />
          </a>
          <a
            target='_blank'
            href={"https://x.com/FallowRaoMovva?t=TtOXJBp-ztKfOUZNiDYFXQ&s=09"}
            //style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
            className='p-[0.5rem] flex justify-center items-center bg-[#FF7E10] rounded-full cursor-pointer transition duration-300 hover:scale-110'>
            <Icon icon='ri:twitter-fill' className='text-white text-[1rem]' />
          </a>
          <a
            target='_blank'
            href={"https://www.youtube.com/@movvarao1060"}
            //style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
            className='p-[0.5rem] flex justify-center items-center bg-[#FF7E10] rounded-full cursor-pointer transition duration-300 hover:scale-110'>
            <Icon icon='ri:youtube-fill' className='text-white text-[1rem]' />
          </a>
        </div>

        <div
          className={`flex  items-center h-auto z-50 absolute ${
            isContactShown ? "left-[30px]" : "left-[-52px]"
          }  top-[55%] xsm:top-[45%] transition-all duration-4000 xl:hidden`}>
          <div className='p-[0.75rem] xl:p-[0.5rem] bg-white flex flex-col gap-[1rem] rounded-[3.125rem] '>
            <a
              href={
                "https://www.facebook.com/share/Wa4ZNZ8DjnuMudjC/?mibextid=qi2Omg"
              }
              target='_blank'
              style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
              className='p-[0.375rem] bg-[#FF7E10] hover:shadow-xl rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
              <Icon
                icon='ri:facebook-fill'
                className='text-white text-[1rem]'
              />
            </a>
            <a
              href={
                "https://www.linkedin.com/in/rao-movva-pfp%C2%AE-cim%C2%AE-ciwm-fcsi%C2%AE-1826263/"
              }
              target='_blank'
              style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
              className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
              <Icon
                icon='ri:linkedin-fill'
                className='text-white text-[1rem]'
              />
            </a>
            <a
              href={
                "https://www.instagram.com/founder_ceo_travelglobal.ca?igsh=bm10cXBka2g5Y3dh"
              }
              target='_blank'
              style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
              className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
              <Icon
                icon='ri:instagram-fill'
                className='text-white text-[1rem]'
              />
            </a>
            <a
              href={
                "https://x.com/FallowRaoMovva?t=TtOXJBp-ztKfOUZNiDYFXQ&s=09"
              }
              target='_blank'
              style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
              className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
              <Icon icon='ri:twitter-fill' className='text-white text-[1rem]' />
            </a>
            <a
              href={"https://www.youtube.com/@movvarao1060"}
              target='_blank'
              style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
              className='p-[0.375rem] bg-[#FF7E10] rounded-full transition duration-300 hover:scale-110 cursor-pointer'>
              <Icon icon='ri:youtube-fill' className='text-white text-[1rem]' />
            </a>
          </div>
          <button
            type='button'
            onClick={() => setContactsShown(!isContactShown)}
            className='w-[2.5rem] h-[2.5rem] bg-white flex justify-center items-center  rounded-r-[3.125rem]  xl:hidden'>
            <Icon
              icon={
                !isContactShown
                  ? "fluent:chevron-right-12-filled"
                  : "fluent:chevron-left-12-filled"
              }
              className='text-[1rem] sm:text-[1.5rem] text-[#000000]'
            />
          </button>
        </div>

        <div class='garden-container'>
          <div class='w-full h-auto  relative'>
            <img
              alt=''
              src={sunFrame}
              class='absolute bottom-0 lg:hidden h-auto w-full'
            />

            <div class='flex flex-row justify-between z-10 w-[95%] absolute bottom-[-0.25rem] md:bottom-[-1rem]  left-0 right-0 overflow-hidden lg:hidden'>
              <img
                alt=''
                src={leftBush}
                class='h-[2.125rem] sm:h-[4.125rem] w-auto '
              />

              <img
                alt=''
                src={bush}
                class='h-[2.125rem] sm:h-[4.125rem] w-auto'
              />
            </div>

            <img
              alt=''
              src={tree}
              class='h-[9.125rem] sm:h-[10.125rem] md:h-[12.125rem] lg:h-[22.125rem] xl:h-[28.125rem] 2xl:h-[35rem] absolute bottom-[-0.50rem] lg:bottom-[-4rem]  right-[-0.15rem] overflow-hidden z-0 '
            />
          </div>
          <div className='grass-container '>
            <img
              alt=''
              src={grassRoot}
              className='absolute h-[0.75rem] w-[0.875rem] top-[2rem] right-[3.5rem] sm:right-[5rem] lg:hidden'
            />
            <img
              alt=''
              src={grassRoot}
              className='absolute h-[0.75rem] w-[0.875rem] top-[3rem] right-[5rem] lg:hidden'
            />
            <img
              alt=''
              src={grassRoot}
              className='absolute h-[0.75rem] w-[0.875rem] top-[3.4rem] right-[3.5rem] lg:hidden'
            />
            <div className='flex flex-row relative w-auto h-auto  top-[-2.5rem] sm:left-[1.5rem] sm:top-0 xl:left-[14rem]  lg:top-[-9rem] xl:top-[-10rem] 1xl:top-[-13rem]'>
              <img
                alt=''
                src={couple}
                className='h-[10rem] sm:h-[15rem] md:h-[18rem]  lg:h-[19rem] xl:h-[20rem] 1xl:h-[24rem] w-auto self-center z-10'
              />
              <img
                alt=''
                src={marketTable}
                className='h-[7.5rem] sm:h-[10.5rem] md:h-[13rem] lg:h-[15rem]  xl:h-[16rem] 1xl:h-[19rem] w-auto self-start ml-[-45px] lg:ml-[-80px] z-0'
              />
            </div>
            <img
              alt=''
              src={chillingChair}
              className='absolute bottom-[1rem] right-[1rem] sm:right-[1.5rem] lg:right-[10rem] h-[6rem] sm:h-[15rem] xl:h-[16rem] w-auto'
            />
          </div>
        </div>
      </div>
      <Popup
        open={videoModalShown}
        ref={videoPopupRef}
        modal
        position={"center center"}>
        {(close) => (
          <VideoModal close={close} setVideoModalShown={setVideoModalShown} />
        )}
      </Popup>
    </div>
  );
};

export default Home;
