import ReactPlayer from "react-player";
import { Icon } from "@iconify/react";

const VideoModal = ({ close, setVideoModalShown }) => {
  return (
    <div className='w-screen px-8  lg:px-0 flex justify-center'>
      <div
        style={{
          background:
            " radial-gradient(483.95% 341.27% at -174.95% 37.09%, #FF790C 0%, #FFB73A 56.73%, #FFBA3C 59.23%, #FFBB41 67.72%, #FFC04E 77.27%, #FFC765 87.34%, #FFD084 97.7%, #FFD38C 100%)",
          fontFamily: "Arial",
        }}
        className='py-[1.5rem] px-[1.5rem]  lg:px-[2.5rem] flex flex-col items-center rounded-[0.625rem] sm:rounded-[1.25rem] gap-[1rem] h-auto w-full lg:w-auto '>
        <button
          onClick={() => setVideoModalShown(false)}
          type='button'
          className='self-end outline-none'>
          <Icon icon='mdi:close' className='text-[1.5rem]' />
        </button>
        <div className='rounded-[0.6rem] overflow-hidden w-full h-[200px] sm:h-[300px] sm:w-[400px] md:w-[600px] md:h-[400px]'>
          <ReactPlayer
            className='rounded-[0.5rem]'
            url={"https://www.youtube.com/watch?v=mkbA9_vyrQ0"}
            controls
            playing={true}
            muted={true}
            width='100%'
            height='100%'
          />
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
